import request from "../../request";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const BIZ_API_URL_V3 = process.env.REACT_APP_PAYMENT_GATEWAY_API_BASE_URL.replace(
  '/api/v1',
  '/v2'
);
export const createAction = (payload) => ({
  type: "",
  payload,
});

export const create = (onFetching, onError, cb, poolRate) => async (
  dispatch
) => {
  const countries = request({
    url: `${process.env.REACT_APP_PAYMENT_GATEWAY_API_BASE_URL}/countries`,
    method: "GET",
  });
  const rates = request({
    url: `${process.env.REACT_APP_PAYMENT_GATEWAY_API_BASE_URL}/currency/rate`,
    method: "GET",
  });
  const currencies = request({
    url: `${BIZ_API_URL_V3}/currency/supported`,
    method: "GET",
  });
  const payload = Promise.all([countries, rates, currencies]);
  onFetching();

  dispatch(createAction(payload)).then(({ value, action, ...error }) => {
    onFetching();
    action && action.payload
      ? cb &&
        cb(
          action.payload,
          `${process.env.REACT_APP_PAYMENT_GATEWAY_API_BASE_URL}/currency/rate`
        )
      : onError(error);
  });
};
export const getLevel = (username, cb, onFetching, onError) => async (
  dispatch
) => {
  const payload = request({
    url: `${BASE_URL}/api/kyc/?username=${username}`,
    method: "GET",
  });
  dispatch(createAction(payload)).then(({ value, action, ...error }) => {
    action && action.payload ? cb && cb(action.payload) : onError(error);
  });
}
