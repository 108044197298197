import styled, { css, keyframes } from "styled-components";
import { colors, fontSizes, fontWeight } from "./mixins";

export const Layout = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[5]};
  width: ${({ theme }) => theme.sizing.flexible};
  display: flex;
  justify-content: center;
  font-family: "Inter";
  padding-bottom: 50px;
  .sect {
    display: flex;
    @media (max-width: 995px) {
      flex-direction: column;
    }
  }
  .all-height {
    // padding-top: 5% !important;
  }
  .content {
    max-width: ${({ theme }) => theme.sizing.large};
    width: ${({ theme }) => theme.sizing.flexible};
    min-height: ${({ theme }) => theme.sizing.flexible};
    padding-bottom: 100px;
    a {
      display: inline-block;
    }
    .rc-steps {
      width: 50%;
      margin-top: 5px;
    }
    .rc-steps-item-title {
      opacity: 0.4;
      color: ${({ theme }) => theme.colors.grey[3]};
      cursor: pointer;
    }
    .rc-steps-dot .rc-steps-item-icon .rc-steps-item-icon,
    .rc-steps-icon-dot,
    .rc-steps-icon,
    .rc-steps-item-icon {
      background: transparent !important;
    }
    .rc-steps-item-icon {
      width: 6px;
      height: 6px;
    }
    .rc-steps-item-active .rc-steps-icon-dot,
    .rc-steps-item-active .rc-steps-item-icon {
      background: ${({ theme }) => `${theme.colors.black[1]} !important`};
    }
    .rc-steps-item-active .rc-steps-item-title {
      opacity: 1;
      color: ${({ theme }) => `${theme.colors.black[1]} !important`};
    }
    .rc-steps-item-finish .rc-steps-item-tail:after {
      opacity: 1 !important;
      background-color: ${({ theme }) => theme.colors.blue[4]} !important;
    }
    .rc-steps-item-finish .rc-steps-item-title {
      opacity: 1;
      color: ${({ theme }) => theme.colors.blue[4]} !important;
    }
    .rc-steps-icon {
      top: 0px;
    }
    .rc-steps-dot .rc-steps-item-tail:after {
      height: 2px;
      margin-top: 1px;
      opacity: 0.4;
      background-color: ${({ theme }) => theme.colors.grey[3]};
    }
    .main {
      display: flex;
      padding: 0 10px;
      // margin-top: -50px;
    }
    .main_detail {
      width: 100%;
      background: none;
    }
    .main__view {
      width: 100%;
      background: none;
      margin-bottom: 50px;
    }
  }
  @media (min-width: 768px) {
    ${({ auto }) =>
      !auto &&
      css`
        height: 100%;
      `}
  }
  @media (max-width: 768px) {
    ${({ home }) =>
      home &&
      css`
        padding-top: 0 !important;
      `}
      .all-height {
        padding-top: 70px  !important;
    }

  .no-marg{
      font-size: 3.2rem !important;
    }
    .main{
      margin-top: 50px;
    }
  }
  @media (min-width: 769px) and (max-width: 988px) {
    .main__view {
      /* padding: 20px !important; */
    }
    .all-height {
      padding-top: 0% !important;
    }
    .no-marg {
      margin-top: 0;
    }
  }
`;

export const Nav = styled.nav`
  min-width: ${({ theme }) => theme.sizing.small};
  a {
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Inter";
    transition: all 100ms ease-in-out;
    position: relative;
    .nav--img,
    .nav--img__active {
      width: 20.4px;
      height: 21.5px;
      object-fit: contain;
      display: block;
      transition: all 200ms ease-in-out;
      margin-right: 11px;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .nav--img__active {
      display: none;
      transition: all 200ms ease-in-out;
    }
    .title {
      opacity: 0.8;
      font-family: "Inter";
      font-size: ${({ theme }) => theme.fontSizes[8]};
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: ${({ theme }) => theme.colors.grey[3]};
      transition: all 100ms ease-in-out;
    }
  }
  a:hover {
    .title {
      opacity: 1;
      color: ${({ theme }) => theme.colors.black[1]};
    }
  }

  .is--nav--active {
    opacity: 1;
    .nav--img__active {
      display: block;
    }
    .nav--img {
      display: none;
    }
    .title {
      opacity: 1;
      color: ${({ theme }) => theme.colors.black[1]};
    }
  }
  @media (max-width: 768px) {
    position: fixed;
    display: flex;
    bottom: 0;
    width: 100%;
    justify-content: space-between;
    padding: 4.5px 10px 4px;
    border: solid 0.5px #dae0ea;
    background-color: #ffffff;
    margin: 0;
    height: 60px;
    z-index: 20000000000;
    .title {
      margin-block: 0;
    }
    a{
      flex-direction: column;
      justify-content: space-around;
    }
    .nav--img,
    .nav--img__active{
      margin-right: 0;
    }
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ mb }) => mb || "80px"};
  margin-top: 10px;
  .title {
    font-family: "Inter";
    font-size: ${({ theme }) => theme.fontSizes[7]};
    line-height: 18px;
    text-align: left;
    color: ${({ theme }) => theme.colors.grey[4]};
    margin-bottom: 10px;
  }
`;

export const Text = styled.span`
  font-family: "Inter";
  line-height: 24px;
  text-align: left;
  ${colors};
  ${fontSizes};
  ${fontWeight}
`;

export const Balance = styled.div`
  border-radius: 10px;
  background-color: ${({ theme, color }) => theme.colors[color].default};
  padding: 15px 20px 20px 20px;
  width: 32%;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 28px;
      height: 28px;
    }
  }
  @media (max-width: 988px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 988px) {
    flex-direction: column;
    .stretch {
      width: 100% !important;
    }
  }
`;

export const Activity = styled.div`
  margin-top: 12px;
  margin-bottom: 10px;
  .detail {
    display: flex;
    align-items: flex-start;

    img {
      display: block;
      width: 36px;
      height: 36px;
      margin-right: 26px;
    }
    .more {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const Action = styled.div`
  width: 100%;
  background-color: ${({ theme, color, variation }) =>
    theme.colors[color][variation]};
  padding: 30px 20px 20px 20px;
  border-radius: 5px;
  margin-bottom: 20px;

  a {
    text-decoration: none;
    svg {
      transition: all 300ms ease-in-out;
      .cls-1-new {
        fill: ${({ theme, color, fontvariation }) =>
          theme.colors[color][fontvariation]};
      }
    }
  }

  a:hover {
    svg {
      transform: translateX(5px);
    }
  }
  img {
    width: 72.2px;
    height: auto;
    display: block;
  }
`;

export const Withdraw = styled.span`
  margin-right: 10px;
  .cls-1-new-up {
    width: 20px;
    height: 20px;

    fill: ${({ theme, color, variation }) => theme.colors[color][variation]};
    stroke: ${({ theme, color, variation }) => theme.colors[color][variation]};
  }
`;

export const TansparentButton = styled.button`
  border: none;
  background: none;
  box-shadow: none;
  outline: none;
  padding: 0;
`;

export const Payments = styled.div`
  .rc-tabs-top .rc-tabs-tab {
    font-family: "Inter";
    font-size: ${({ theme }) => theme.fontSizes[8]};
    font-weight: ${({ theme }) => theme.thickness[5]};
    color: ${({ theme }) => theme.colors.grey[3]};
    line-height: 20px;
    text-align: center;
    padding: 18px 64px;
    padding-top: 0 !important;
    margin-right: 0;
    transition: all 300ms ease-in-out;
  }
  .rc-tabs-tab-active {
    font-weight: ${({ theme }) => theme.thickness[6]} !important;
    color: ${({ theme }) => theme.colors.black[1]} !important;
  }
  .rc-tabs-ink-bar {
    height: 1px !important;
    background-color: ${({ theme }) => theme.colors.blue[5]} !important;
  }
  .rc-tabs-top .rc-tabs-bar,
  .rc-tabs-top {
    border-bottom: 0px;
  }
  .rc-tabs-top .rc-tabs-nav-scroll {
    max-width: 100% !important;
    width: 100% !important;
  }
  .rc-tabs-nav {
    margin-bottom: 10px;
  }
  @media (max-width: 1024px) {
    .rc-tabs-top .rc-tabs-tab {
      padding: 10px 30px;
    }
  }
`;


export const AdButton = styled.button`
  padding: 6px 14.5px;
  border-radius: 10px;
  background-color: rgba(20, 204, 28, 0.07);
  border: none;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #14cc1c;
  display: flex !important;
  text-decoration: none;
  align-items: center;
  svg {
    margin-left: 10px;
    width: 12px;
    transition: 300ms ease-in-out;
  }
  transition: 300ms ease-in-out;
  :hover {
    background-color: #00a1cd;
    /* transform: translateY(2px); */
    background-color: rgba(20, 204, 28, 0.1);
    svg {
      transform: translateX(5px);
    }
  }
  ${({ hide }) =>
    hide &&
    css`
      @media (max-width: 995px) {
        display: none !important;
      }
    `}
  ${({ show }) =>
    show &&
    css`
      display: none !important;
      @media (max-width: 995px) {
        width: 100%;
        margin-top: 74px;
        display: flex !important;
        margin-bottom: 50px;
      }
    `}
`;

export const StoreButton = styled.button`
  border-radius: 10px;
  border: solid 1px
    ${({ type }) =>
      type === "google"
        ? "rgba(234, 67, 53, 0.35)"
        : "rgba(28, 159, 247, 0.35)"};
  background-color: ${({ type }) =>
    type === "google" ? "rgba(234, 67, 53, 0.07)" : "rgba(28, 159, 247, 0.07)"};
  padding: 12px 40px;
  display: inline-flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  color: ${({ type }) => (type === "google" ? "#ea4335" : "#1c9ff7")};
  transition: 300ms ease-in-out;
  position: relative;
  svg {
    margin-right: 15px;
    height: 20px;
    width: auto;
  }
  :after {
    transition: 50ms ease-in-out;
    content: "";
  }
  :hover {
    /* transform: translateY(2px); */
    :after {
      transition: 200ms ease-in-out;
      content: "Coming soon";
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
      background-color: ${({ type }) =>
        type === "google" ? "rgba(234, 67, 53, 1)" : "rgba(28, 159, 247, 1)"};
      border-radius: 9px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
    }
  }

  @media (max-width: 995px) {
    margin-right: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
  }
`;

export const OutBoundLink = styled.button`
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #11b2e4;
  display: inline-flex !important;
  align-items: center;
  text-decoration: none;
  margin-top: 45px;
  background-color: transparent;
  border: none;
  transition: 300ms ease-in-out;
  cursor: pointer;
  svg {
    width: 25px;
    height: auto;
    margin-right: 20px;
  }
  :hover {
    transform: translateY(2px);
  }
  @media (max-width: 995px) {
    margin: 0 auto;
    width: 100%;
    justify-content: center;
  }
`;

export const Pill = styled.div`
  width: 70%;
  border-radius: 24px;
  font-size: 1.42rem;
  background-color: rgba(0, 179, 228, 0.1);
  margin: 0 auto;
  height: 42px;
  margin: auto;
  margin-bottom: 22px;
  position: relative;
  button {
    border: none;
    font-size: 1.42rem;
    background: transparent;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #00b3e4;
    height: 100%;
    width: 50%;
    text-align: center;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }
  .active-indicator {
    width: 50%;
    height: 100%;
    border-radius: 24px;
    font-size: 1.42rem;
    background-color: #00b3e4;
    position: absolute;
    text-align: center;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: 300ms ease-in-out;
    transform: translateX(${({ left }) => (left ? 0 : "100%")});
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;
const fadeIn = keyframes`
  from {
    opacity:0;
  }

  to {
   opacity:1;
  }
`;
export const More = styled.div`
  padding: 11px 15px;
  border-radius: 2.5px;
  border: solid 0.5px rgba(138, 108, 59, 0.15);
  background-color: #fcf8e3;
  margin-top: 26px;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  margin-top: 40px;
  animation: ${fadeIn} 0.5s linear;
  .head {
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    color: #8a6c3b;
    display: block;
    margin-bottom: 5px;
  }
  .content {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #8a6c3b;
    a {
      color: #8a6c3b;
      text-decoration: underline;
    }
  }
`;
