import React, { Fragment } from "react";

import {
  Main,
  Menu,
  Container,
  BurgerButton,
  LinkButton,
  ContainerLogo,
} from "./elements";
import { ReactComponent as Logo } from "../../assets/brand.svg";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import signout from "./signout.action";
class App extends React.Component {
  state = {
    expand: false,
  };
  onToggle = () =>
    this.setState((prevState) => ({ expand: !prevState.expand }));

  render() {
    const { expand } = this.state;
    const { Center, user, styles, light, dashboard } = this.props;
    return (
      <Container role="navigation" style={styles} light className="navigation">
        <Main>
          <ContainerLogo lean={!!Center}>
            <Link to="/">
              <Logo />
            </Link>
            {!!Center || (
              <Fragment>
                <LinkButton
                  to=""
                  as="a"
                  href="https://medium.com/coinprofile"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Blog
                </LinkButton>
                <LinkButton
                  as="a"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={process.env.REACT_APP_FAQ}
                >
                  FAQs
                </LinkButton>
              </Fragment>
            )}
          </ContainerLogo>
          {light ? <div className="hidden">{Center}</div> : Center}
          <div className="menu-cont-large">
            {dashboard && (
              <LinkButton
                outlined="true"
                to={{ pathname: "/remittance/amount", search: "?type=send" }}
              >
                &#8599; Send Money
              </LinkButton>
            )}
            {Center && !!user.token && !!user.data ? (
              <Fragment>
                <LinkButton colored="true" to="/home">
                  Welcome
                </LinkButton>
              </Fragment>
            ) : !!user.token && !!user.data ? (
              <LinkButton colored="true" to={`/home`}>
                Welcome
              </LinkButton>
            ) : light ? (
              <LinkButton
                colored="true"
                as="a"
                href="https://www.coinprofile.com/"
                rel="noreferrer noopener"
              >
                Get a Profile
              </LinkButton>
            ) : (
              <Fragment>
                <LinkButton to="/login">Log In</LinkButton>
                <LinkButton
                  colored="true"
                  as="a"
                  href="https://www.coinprofile.com/"
                  rel="noreferrer noopener"
                >
                  Get a Profile
                </LinkButton>
              </Fragment>
            )}
          </div>
          <div className="menu-cont-small">
            <BurgerButton onClick={this.onToggle} expand={expand}>
              <span className="bar" />
              <span className="bar" />
              <span className="bar" />
            </BurgerButton>
          </div>
          <Menu expand={expand}>
            <LinkButton
              as="a"
              href={process.env.REACT_APP_FAQ}
              target="_blank"
              to="#"
            >
              Help Centre &#8599;
            </LinkButton>
            {!!user.token && !!user.data && Center ? (
              <Fragment>
                <LinkButton
                  colored="true"
                  to="/home"
                  onClick={() => {
                    this.onToggle();
                    // this.props.signout();
                  }}
                  style={{ marginTop: 15 }}
                >
                  Home
                </LinkButton>
              </Fragment>
            ) : !!user.token && !!user.data ? (
              <LinkButton colored="true" to={`/home`}>
                Home
              </LinkButton>
            ) : (
              <Fragment>
                <LinkButton to="/login" onClick={this.onToggle}>
                  Log In
                </LinkButton>
                <LinkButton
                  colored="true"
                  as="a"
                  href="https://www.coinprofile.com/"
                  rel="noreferrer noopener"
                  onClick={this.onToggle}
                >
                  Get a Profile
                </LinkButton>
              </Fragment>
            )}
          </Menu>
        </Main>
        <div className="show-center">{light && Center}</div>
      </Container>
    );
  }
}
const mapDispatchToProps = {
  signout,
};
const mapStateToProps = ({ user }) => ({ user: user.resp });
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
