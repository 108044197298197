import styled, { css } from "styled-components";

export const Container = styled.div`
  margin: 0 30px;
  max-width: 292px;

  h1 {
    font-size: 1.25rem;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #1f2023;
    font-family: "Inter";
  }
  p {
    font-size: 1.25rem;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #545969;
    font-family: "Inter";
  }
  .numbering {
    font-size: 2.5rem;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #00b3e4;
    margin: 0;
    font-family: "Inter";
  }
  .type_two_numbering {
    font-size: 2.5rem;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #00b3e4;
    margin: 0;
    font-family: "Inter";
    display: inline-flex;
    height: 50px;
    width: 50px;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: rgba(0, 179, 228, 0.1);
    margin-bottom: 21px;
  }
  ${({ special }) =>
    special &&
    css`
      margin: 0;
    `}
  margin-left: 40px;
  margin-right: 20px;
  @media (max-width: 767px) {
    margin: 0;
    width: 100%;
    max-width: 100%;
    padding: 0 30px;
    h1,
    p,
    .numbering {
      text-align: center;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
