import React, { useState } from "react";
import PropTypes from "prop-types";
import Error from "../notification";
import { InputContainer } from "./elements";
import { ReactComponent as Hide } from "./icons/hide.svg";
import { ReactComponent as Show } from "./icons/show.svg";

const App = ({
  sm,
  name,
  type,
  error,
  title,
  large,
  value,
  select,
  options,
  disabled,
  textArea,
  onChange,
  labellink,
  placeholder,
  borderless,
  children,
  style,
}) => {
  const [state, setState] = useState({
    show: false,
    type: "password",
  });
  const isPassword = type === "password";
  const refinedType = isPassword ? state.type : type;
  const onShow = () => {
    state.show
      ? setState({
          show: false,
          type: "password",
        })
      : setState({
          show: true,
          type: "text",
        });
  };

  return (
    <InputContainer
      isPassword={isPassword}
      borderless={borderless}
      large={large}
      style={style}
      sm={sm}
    >
      {title && (<label>
        {title}
        {labellink && labellink}
      </label>)}
      {select ? (
        <select
          onChange={onChange}
          name={name}
          value={value}
          disabled={disabled}
        >
          {options.map((val) => (
            <option key={val.key || val} value={val.value || val}>
                {val.option || val}
            </option>
          ))}
        </select>
      ) : textArea ? (
        <textarea
          value={value}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          name={name}
        />
      ) : (
        <input
          disabled={disabled}
          value={value}
          type={refinedType}
          placeholder={placeholder}
          onChange={onChange}
          name={name}
        />
      )}
      {isPassword && (
        <span className="show-icon" onClick={onShow}>
          {state.show ? <Hide /> : <Show />}
        </span>
      )}
      {children}
      <Error error={error} />
    </InputContainer>
  );
};

App.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  error: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string.isRequired,
  textArea: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  select: PropTypes.bool,
  disabled: PropTypes.bool,
};

App.defaultProps = {
  error: "",
  options: [],
  select: false,
  disabled: false,
};
export default App;
