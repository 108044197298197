import {
  CONFIRM,
  SIGN_OUT,
  GET_USER,
  TWO_FACTOR,
  UPDATE_USER,
  CLEAR_USER_ERROR
} from "./constants/actionTypes";

const initialState = {
  fetching: false,
  fetched: false,
  onError: false,
  error: {},
  resp: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER.pending: {
      return {
        ...state,
        fetching: true,
        fetched: false,
        onError: false,
        error: {}
      };
    }
    case UPDATE_USER.fulfilled: {
      return {
        ...state,
        resp: { ...state.resp, data: action.payload.data.data }
      };
    }
    case GET_USER.fulfilled:
    case CONFIRM.fulfilled: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: {},
        resp: action.payload.data ? action.payload.data : {}
      };
    }
    case TWO_FACTOR.fulfilled: {
      return {
        ...state,
        resp: {
          ...state.resp,
          data:
            action.payload.data.data && action.payload.data.data.enrolled
              ? { ...state.resp.data, mfa: { enrolled: true } }
              : { ...state.resp.data, mfa: null }
        }
      };
    }
    case GET_USER.rejected: {
      return {
        ...state,
        fetching: false,
        fetched: false,
        onError: true,
        error: action.payload
      };
    }
    case CLEAR_USER_ERROR.default: {
      return { ...state, fetched: false, onError: false, error: {} };
    }
    case SIGN_OUT.default: {
      return { ...initialState };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export default reducer;
