import React from "react";
import { Text } from "../styles/elements";
import Download from "./Download";
// import Typewriter from "typewriter-effect";

const App = ({ onShow }) => {
  return (
    <section style={{ flex: 1 }} className={`animated fadeIn`}>
      <Text
        className="no-marg make-center"
        size={19}
        thickness={9}
        as="h1"
        style={{ lineHeight: "70px", textAlign: "left" }}
      >
        Instant money exchange
      </Text>
      <Text
        as="h2"
        size={20}
        thickness={4}
        style={{ lineHeight: "37px" }}
        className="make-center"
      >
        Send and receive money with friends and family, exchange your Crypto for
        local currency. No sign up required. It’s crazy fast and with zero fees.
      </Text>
      <Download mystyle="hide-this" onShow={onShow} />
    </section>
  );
};

export default App;
