import React from "react";
import { ContainerColumn } from "../forms/elements";
import Amount from "../forms/Amount";
import { Pill } from "../styles/elements";
import Loading from "../../../containers/Loading";
import Context from "../Context";

class App extends React.Component {
  state = {
    resp: {},
    fetching: false,
    onError: false,
    error: {},
    country: "Anywhere",
    active: "Sell",
  };

  onFetching = () => {
    this.setState(({ fetching }) => ({ fetching: !fetching, onError: false }));
  };

  onError = (error) => {
    this.setState({ error, onError: true });
  };

  cb = () => {
    this.setState({ verified: true });
  };

  handlePropagation = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  setActive = (active) => {
    this.setState({ active });
  };
  render() {
    const { fetchedRate } = this.context.state;
    return (
      <section
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <ContainerColumn
          onClick={this.handlePropagation}
          home
          style={{
            width: "100%",
            alignItems: "flex-end",
            justifyContent: "flex-start",
            padding: "15px",
            paddingTop: "10%",
            overflowX: "hidden",
            minHeight: "695.19px",
          }}
        >
          <div style={{ maxWidth: "450px", width: "100%" }}>
            <Pill left={"Send" === this.state.active}>
              <div className="active-indicator">{`${
                this.state.active === "Sell" ? "Sell Crypto" : "Send Money"
              }`}</div>
              <button onClick={() => this.setActive("Send")}>Send Money</button>
              <button onClick={() => this.setActive("Sell")}>
                Sell Crypto
              </button>
            </Pill>
          </div>
          {!fetchedRate ? (
            <Loading
              styles={{
                background: "transparent",
                minHeight: "unset",
                maxHeight: "calc(100% - 200px)",
                display: "flex",
                justifyContent: "flex-end",
              }}
            />
          ) : (
            <Amount hidetitle type={this.state.active} />
          )}
        </ContainerColumn>
      </section>
    );
  }
}
export default App;

App.contextType = Context;
