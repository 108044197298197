const text = [
  {
    text:
      "We make sending money simple with easy to understand steps. Cutting out all complexities.",
    title: "Easier",
    icon:
      "https://res.cloudinary.com/dpmxguze6/image/upload/v1589062124/dashboard_assets/digital-marketing-marketing-content-marketing-puzzle-piece-strategy.svg",
  },
  {
    text:
      "Sending money with Coinprofile takes under 5 minutes - much faster than other services",
    title: "Faster",
    icon:
      "https://res.cloudinary.com/dpmxguze6/image/upload/v1589062145/dashboard_assets/logistic-delivery-van-transportation-transport-vehicle-express-speed.svg",
  },
  {
    text:
      "We offer the best exchange rate so you get better value for your money when you send with Coinprofile. ",
    title: "Cheaper",
    icon:
      "https://res.cloudinary.com/dpmxguze6/image/upload/v1589062199/dashboard_assets/crowdfunding-crowd-funding-finance-money-growth.svg",
  },
];

export default text;
