import React from "react";
import { Layout } from "./styles/elements";
import Header from "../header";
import Stepper from "./Stepper";
import { withRouter } from "react-router-dom";

const App = ({ children, location }) => {
  const { pathname, hash } = location;
  const islanding =
    pathname === "/remittance" ||
    pathname === "/remittance/" ||
    hash === "#more";

  return islanding ? (
    <>{children}</>
  ) : (
    <Layout auto>
      <div className="content">
        <Header Center={<Stepper />} light />
        <div className="main">
          <section className="main_detail">
            <div className="main__view" style={{ minHeight: "300px" }}>
              {children}
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default withRouter(App);
