import React from "react";
import { Container } from "./elements";

class App extends React.Component {
  render() {
    const {
      onClose,
      link = "https://www.youtube.com/embed/K_d1kKpOc9c",
    } = this.props;
    return (
      <Container onClick={onClose} id="video">
        <span
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <div className="edit-address-cont">
            <iframe
              title="coinprofile demo"
              src={link}
              allow="accelerometer;fullscreen; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </span>
      </Container>
    );
  }
}

export default App;
