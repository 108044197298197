import React, { lazy, useState, Suspense } from "react";
import { useSelector } from "react-redux";
import { Layout } from "../styles/elements";
import { ThemeProvider } from "styled-components";
import theme from "../../theme";
import Header from "../../header";
import Left from "./Left";
import Right from "./Right";
import Demo from "../../home/demo";
import {
  SubTitle,
  SectionTwo,
  SectionFour,
  DetailsContainer,
} from "../../home/elements";
// import Download from "./Download";
import Loading from "../../../containers/Loading";
import InViewMonitor from "react-inview-monitor";
import { Ask as OutBoundLink } from "../../terms/elements";
import howitworks from "../constants/howitworks";
import sendmoney from "../constants/sendmoney";
import { HashLink as Link } from "react-router-hash-link";
import Context from "../Context";
import Details from "../../details";

const Amount = lazy(() => import("../forms/Amount"));

const App = ({ fetchedRate }) => {
  const [show, setShow] = useState(false);
  const user = useSelector(({ user }) => user.resp.data);
  const onShow = () => setShow(!show);

  return (
    <ThemeProvider theme={theme}>
      <>
        <Layout auto home style={{ minHeight: "100%", paddingTop: "50px" }}>
          {show && (
            <Demo
              onClose={onShow}
              link="https://www.youtube.com/embed/yrHgdWS8XTY"
            />
          )}
          <section className="content" style={{ paddingBottom: "0" }}>
            <Header styles={{ maxWidth: "100%" }} />
            <section className="main all-height">
              <section
                className="main_detail"
                style={{ display: "flex", alignItems: "center" }}
              >
                <section className="main__view" style={{ marginBottom: 0 }}>
                  <section className="sect" style={{ alignItems: "center" }}>
                    <Left onShow={onShow} />
                    <Right fetchedRate={fetchedRate} />
                  </section>
                  {/* <Download mystyle="show-this" /> */}
                  {/* <AdButton as="button" onClick={onShow} show="true">
                    &#9658; Learn how it works
                  </AdButton> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <OutBoundLink
                      to="/remittance/#more"
                      bounce="true"
                      style={{
                        fontSize: "1.4rem",
                        margin: "0px",
                        fontWeight: 500,
                        textAlign: "right",
                        color: "#1f2023",
                        display: "flex",
                        alignItems: "center",
                      }}
                      as={Link}
                    >
                      <span style={{ marginRight: "5px" }}>How it works </span>
                      <img
                        width="12px"
                        alt="more"
                        src="https://res.cloudinary.com/dpmxguze6/image/upload/v1589067151/dashboard_assets/Icons___Show_password.svg"
                      />
                    </OutBoundLink>
                  </div>
                </section>
              </section>
            </section>
          </section>
        </Layout>
        <Layout style={{ background: "#ffffff" }} id="more" auto>
          <section className="content" style={{ paddingBottom: "0" }}>
            <SectionTwo style={{ border: "none" }}>
              <SubTitle style={{ marginBottom: "40px", fontSize: "2.1rem" }}>
                Here’s how it works
              </SubTitle>
              <DetailsContainer special>
                {howitworks.map(({ title, text }, key) => (
                  <InViewMonitor
                    key={title}
                    classNameNotInView="vis-hidden"
                    classNameInView={`animated flipInX td-${key}00 custom-grid`}
                  >
                    <Details
                      title={title}
                      text={text}
                      number={`${key}`}
                      special
                    />
                  </InViewMonitor>
                ))}
              </DetailsContainer>
            </SectionTwo>
            <SectionTwo style={{ border: "none" }}>
              <SubTitle style={{ marginBottom: "40px", fontSize: "2.1rem" }}>
                A better way to send money
              </SubTitle>
              <DetailsContainer special front>
                {sendmoney.map(({ title, text, icon }, key) => (
                  <InViewMonitor
                    key={`${title}${key}`}
                    classNameNotInView="vis-hidden"
                    classNameInView={`animated flipInX td-${key}00 custom-grid`}
                  >
                    <Details
                      title={title}
                      image={icon}
                      text={text}
                      number={`${key}`}
                      special
                      front
                    />
                  </InViewMonitor>
                ))}
              </DetailsContainer>
            </SectionTwo>
          </section>
        </Layout>
        <Layout style={{ background: "#00b3e4" }} auto>
          <section className="content" style={{ paddingBottom: 0 }}>
            <SectionFour
              style={{
                border: "none",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <SubTitle
                style={{
                  marginBottom: "36px",
                  fontSize: "2.85rem",
                  color: "#ffffff",
                  marginTop: "3%",
                }}
              >
                Sell your Crypto with Coinprofile
              </SubTitle>
              <p style={{ maxWidth: "816px", color: "#ffffff" }}>
                Sell up to $1000 of your Crypto directly to your bank account
                with Coinprofile. No sign ups required. We offer the best rate
                in the market. See for yourself{" "}
                <span role="img" aria-labelledby="here">
                  👇
                </span>
              </p>
              <div
                style={{
                  marginTop: "10%",
                }}
              >
                <Suspense
                  fallback={
                    <Loading
                      styles={{
                        background: "transparent",
                        minHeight: "unset",
                        maxHeight: "calc(100% - 200px)",
                        height: "400px",
                      }}
                    />
                  }
                >
                  {fetchedRate ? (
                    <InViewMonitor
                      classNameNotInView="vis-hidden"
                      classNameInView={`animated fadeIn td-000 custom-grid mb-35`}
                    >
                      <Amount hidetitle type="Sell" />
                    </InViewMonitor>
                  ) : (
                    <Loading
                      styles={{
                        background: "transparent",
                        minHeight: "unset",
                        maxHeight: "calc(100% - 200px)",
                        height: "50px",
                        width: "50px",
                      }}
                    />
                  )}
                </Suspense>
              </div>
            </SectionFour>
          </section>
        </Layout>
        <Layout
          id="faq"
          style={{ background: "#ffffff", padding: "0 20px" }}
          auto
        >
          <section className="content" style={{ paddingBottom: 0 }}>
            <SectionFour style={{ border: "none", paddingBottom: 0 }}>
              <SubTitle
                style={{
                  marginBottom: "36px",
                  fontSize: "2.85rem",
                  color: "#1f2023",
                  marginTop: "35px",
                }}
              >
                Our Frequently Asked Questions
              </SubTitle>
              <OutBoundLink
                href="mailto:support@coinprofile.co"
                style={{ fontSize: "1.7rem", marginBottom: "45px" }}
              >
                Ask a question <span id="arrow">&#x2192;</span>
              </OutBoundLink>
              <InViewMonitor
                classNameNotInView="vis-hidden"
                classNameInView={`animated fadeIn td-000 custom-grid  mb-35`}
              >
                <div>
                  <SubTitle
                    style={{
                      fontSize: "1.71rem",
                      color: "#1f2023",
                    }}
                  >
                    Where can I send money from?
                  </SubTitle>
                  <p
                    style={{
                      maxWidth: "816px",
                      color: "#1f2023",
                      fontSize: "1.42rem",
                      lineHeight: "35px",
                    }}
                  >
                    You can send money from anywhere In the world with Crypto.
                    You can use any app that supports Crypto - Cashapp,
                    Coinbase, Revolut, Robinhood and a lot more!
                  </p>
                </div>
              </InViewMonitor>
              <InViewMonitor
                classNameNotInView="vis-hidden"
                classNameInView={`animated fadeIn td-100 custom-grid  mb-35`}
              >
                <div>
                  <SubTitle
                    style={{
                      fontSize: "1.71rem",
                      color: "#1f2023",
                    }}
                  >
                    How much can I send?
                  </SubTitle>
                  <p
                    style={{
                      maxWidth: "816px",
                      color: "#1f2023",
                      fontSize: "1.42rem",
                      lineHeight: "35px",
                    }}
                  >
                    You can send up to $2000 equivalent in Crypto without
                    creating an account. To increase your limit, please{" "}
                    {user ? (
                      <OutBoundLink href="/dashboard/settings">
                        go to your dashboard
                      </OutBoundLink>
                    ) : (
                      <OutBoundLink href="/register">
                        create an account
                      </OutBoundLink>
                    )}{" "}
                    and upgrade your KYC level.
                  </p>
                </div>
              </InViewMonitor>
              <InViewMonitor
                classNameNotInView="vis-hidden"
                classNameInView={`animated fadeIn td-200 custom-grid mb-35`}
              >
                <div>
                  <SubTitle
                    style={{
                      fontSize: "1.71rem",
                      color: "#1f2023",
                    }}
                  >
                    I’ve got questions, how can I reach you?
                  </SubTitle>
                  <p
                    style={{
                      maxWidth: "816px",
                      color: "#1f2023",
                      fontSize: "1.42rem",
                      lineHeight: "35px",
                    }}
                  >
                    You can reach us on{" "}
                    <OutBoundLink
                      href="https://t.me/coinprofile"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ margin: 0 }}
                    >
                      telegram
                    </OutBoundLink>{" "}
                    , shoot us an email at{" "}
                    <OutBoundLink
                      href="mailto:support@coinprofile.co"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ margin: 0 }}
                    >
                      support@coinprofile.co
                    </OutBoundLink>{" "}
                    or call{" "}
                    <OutBoundLink
                      href="tel:+12069228971"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ margin: 0 }}
                    >
                      +1(206) 922-8971
                    </OutBoundLink>{" "}
                    /{" "}
                    <OutBoundLink
                      href="tel:+2348137457862"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ margin: 0 }}
                    >
                      +234(813) 745-7862
                    </OutBoundLink>
                    . We’re lightning fast at replying :)
                  </p>
                </div>
              </InViewMonitor>
              <hr
                style={{
                  width: "77px",
                  height: "1px",
                  marginTop: "35px",
                  borderRadius: "25px",
                  backgroundColor: "rgba(31, 32, 35, 0.29)",
                }}
              />
              <OutBoundLink style={{ marginTop: "70px" }} as="p">
                &copy; CoinProfile
              </OutBoundLink>
              <OutBoundLink as="p">
                We{" "}
                <span role="img" aria-labelledby="love">
                  ♥️
                </span>{" "}
                Crypto
              </OutBoundLink>
            </SectionFour>
          </section>
        </Layout>
      </>
    </ThemeProvider>
  );
};

function LandingPageContainer() {
  return (
    <Context.Consumer>
      {({ state: { fetchedRate } }) => <App fetchedRate={fetchedRate} />}
    </Context.Consumer>
  );
}

export default LandingPageContainer;
