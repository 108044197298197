import React from "react";
import { OutBoundLink } from "../styles/elements";
import { ReactComponent as Twitter } from "../../../assets/twitter.svg";
import { AdButton } from "../styles/elements";
const App = ({ mystyle, onShow }) => {
  return (
    <div style={{ marginTop: "45px" }} className={mystyle}>
      <div className="downld">
        <AdButton
        style={{marginRight: 30}}
         as="a" 
                  href={process.env.REACT_APP_HOW_IT_WORKS}
                  target="_blank"
                  rel="noopener noreferrer">
          &#9658; Learn how it works
        </AdButton>
        <OutBoundLink
          style={{ marginTop: 0 }}
          as="a"
          href={process.env.REACT_APP_TWITTER}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Twitter /> Join the movement on Twitter
        </OutBoundLink>
      </div>
    </div>
  );
};

export default App;
