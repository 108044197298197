import { CONFIRM } from "./constants/actionTypes";

const initialState = {
  fetching: false,
  fetched: false,
  onError: false,
  error: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CONFIRM.pending: {
      return {
        ...state,
        fetching: true,
        fetched: false,
        onError: false,
        error: {}
      };
    }
    case CONFIRM.fulfilled: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: {}
      };
    }
    case CONFIRM.rejected: {
      return {
        ...state,
        fetching: false,
        fetched: false,
        onError: true,
        error: action.payload
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export default reducer;
