import React, {useEffect} from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Remittance from "../../containers/Remittance";
import Other from "./Other";
import { useSelector } from "react-redux";
import Crypto from 'crypto';

const App = () => {
  const { resp } = useSelector(({ user }) => user);

  useEffect(() => { 
    const { data } = resp
    if (data) {
      let dataLayer = window.dataLayer || []
      const hash = Crypto.createHmac('sha256', process.env.REACT_APP_INTERCOM_SECRET)
      .update(data.email)
      .digest('hex')

      dataLayer.push({
        event: 'user',
        loggedIn: true,
        email: data.email,
        username: data.username,
        createdAt: data.createdAt,
        name: data.fullname || '',
        photo: data.photo,
        userHash: hash
      })
    }
  }, [resp]);

  return(
  <>
    <Switch>
      <Redirect exact from="/" to="/remittance" />
      <Route path="/remittance" component={Remittance} />
      <Route path="/" component={Other} />
    </Switch>
  </>
);}
export default App;
