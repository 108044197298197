import * as yup from "yup";

let schema = yup.object().shape({
  amount: yup.string().required(),
  fiat_amount: yup.number().min(25),
  reciever_country: yup.string().required(),
  sender_country: yup.string().required(),
});

export default schema;
