import styled, { css, keyframes } from "styled-components";

export const FaqContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 632px;
  margin: 0 auto;
  padding: 0 12px;
`;

export const Title = styled.h1`
  font-family: "Inter";
  text-align: left;
  color: #1f2023;
  font-weight: 600;
  margin-bottom: 60px;
  margin-top: 0;
  span {
    display: block;
  }
  .small {
    margin-top: 5px;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #757a88;
    display: block;
  }
  @media (max-width: 768px) {
    margin-bottom: 25px;
  }
`;

export const QandAContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 608px;
  margin-bottom: 12px;
`;

export const Subtitle = styled.h2`
  font-weight: 500;
  text-align: left;
  color: #1f2023;
  font-family: "Inter";
`;
export const Content = styled.p`
  font-family: "Inter";
  font-weight: 500;
  text-align: left;
  color: #757a88;
  margin-top: 0;

  a {
    color: #00b3e4;
    font-family: "Inter";
    text-decoration: none;
  }
`;
export const SubtitleBig = styled(Subtitle)`
  font-weight: 600;
  text-align: center;
  margin-top: 85px;
  margin-bottom: 12px;
  @media (max-width: 767.9px) {
    margin-top: 45px;
  }
`;
const bounceup = keyframes`
  0%{
    transform: translateY(10px);
  }

  50% {
    transform: translateY(0);
  }

  100%{
    transform: translateY(10px);
  }
`;

export const Ask = styled.a`
  color: #00b3e4;
  font-family: "Inter";
  text-decoration: none;
  display: block;
  margin: 0 auto;
  margin-bottom: 68px;
  ${({ bounce }) =>
    bounce &&
    css`
      animation: ${bounceup} 2s linear infinite;
    `}
  @media (max-width: 767.9px) {
    margin-bottom: 34px;
  }
`;

export const Button = styled.button`
  padding: 10px 11px;
  color: #757a88;
  border-radius: 10px;
  background-color: transparent;
  border: none;
  font-size: 14.4px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: left;
  padding: 0;
  outline: none;
  &:hover {
    color: #1f2023;
  }
  ${({ left }) =>
    left &&
    css`
      margin-left: 32px;
    `}
  ${({ active }) =>
    active &&
    css`
      color: #00b3e4;
      &:hover {
        color: #00b3e4;
      }
    `}
`;
export const NavContainer = styled.div`
  margin-bottom: 30px;
`;
