import types from "../../../actionTypes";

export const UPDATE_USER = types("UPDATE_USER");
export const GET_USER = types("GET_USER");
export const REGISTER = types("REGISTER");
export const CONFIRM = types("CONFIRM");
export const CHANGE = types("CHANGE");
export const RESET = types("RESET");
export const TWO_FACTOR = types("TWO_FACTOR");
export const SIGN_OUT = types("SIGN_OUT");
export const CLEAR_USER_ERROR = types("CLEAR_USER_ERROR");
export const CLEAR_REGISTER_ERROR = types("CLEAR_REGISTER_ERROR");
