import React from "react";

import { Container } from "./elements";

const App = ({ title, text, number, special, front, image }) => {
  return (
    <Container special={special} front={front}>
      {front ? (
        <span className="icon">
          <img src={image} alt="more" height="50px" />
        </span>
      ) : special ? (
        <p className="type_two_numbering">{Number(number) + 1}</p>
      ) : (
        <p className="numbering">.0{Number(number) + 1}</p>
      )}
      <h1>{title}</h1>
      <p>{text}</p>
    </Container>
  );
};

export default App;
