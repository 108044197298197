import React from "react";
import isNumeric from "isnumeric";
import Input from "../../Input";
import { Container, ContainerColumn } from "./elements";
import { Button } from "../../header/elements";
import { Formik } from "formik";
import { connect } from "react-redux";
import validation from "../validations/amount";
import { Text } from "../styles/elements";
import Notification from "../../notification";
import { retrieveMessage } from "../../../utils/helpers";
import ExchangeRate from "../ExchangeRate";
import InputSelect from "../../InputSelect";
import COUNTRIES from "../constants/countries";
import { create, getLevel } from "../Amount.action";
import Loading from "../../../containers/Loading";
import { withRouter } from "react-router-dom";
import Context from "../Context";

// This component needs to be rewritten
// it's hard to read and obscure
class App extends React.Component {
  state = {
    resp: {},
    payload: {},
    countries: [],
    currencies: [],
    level: "",
    fetching: false,
    onError: false,
    error: {},
    verified: false,
    selectedSendCurrency: "",
    selectedReceiverCurrency: "",
    initialValues: {
      ...this.context.state,
    },
  };

  onFetching = () => {
    this.setState(({ fetching }) => ({ fetching: !fetching, onError: false }));
  };

  onError = (error) => {
    this.setState({ error, onError: true });
  };

  cb = (value) => {
    const { reciever_country, fiat_amount, recipientCurrency, senderCurrency } =
      this.state.initialValues;
    const { rates } = this.context.state;
    const compareCurrency = value[0].data.data.find(
      (country) => country.country === reciever_country
    );
    const rate = Number(
      rates[
        `${
          this.state.selectedSendCurrency ||
          value[2].data.data.incomingCurrencies[0]
        }${compareCurrency && compareCurrency.currency}`
      ]
    );

    const amount = Number(fiat_amount / rate).toLocaleString("en-US", {
      minimumFractionDigits: 8,
      maximumFractionDigits: 8,
    });

    this.setState(({ initialValues }) => ({
      countries: value[0].data.data,
      currencies: value[2].data.data,
      initialValues: {
        ...initialValues,
        amount,
        senderCurrency:
          senderCurrency || value[2].data.data.incomingCurrencies[0],
        recipientCurrency:
          recipientCurrency || value[2].data.data.outgoingCurrencies[0],
      },
    }));

    this.context.updateState({
      senderCurrency:
        senderCurrency || value[2].data.data.incomingCurrencies[0],
    });
  };
  levelcb = (value) => {
    this.setState((state) => ({
      ...state,
      level: value.data.data.level,
      onError: false,
    }));
  };

  handlePropagation = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  componentDidMount() {
    this.props.create(this.onFetching, this.onError, this.cb);
    this.props.user.username &&
      this.props.getLevel(
        this.props.user.username,
        this.levelcb,
        this.onFetching,
        this.onError
      );
  }

  render() {
    const { fetching, onError, error, initialValues } = this.state;
    let { type } = this.props;
    type = type
      ? type
      : this.props.location.search === "?type=sell"
      ? "Sell"
      : "Send";
    const formattedError = onError ? retrieveMessage(error) : "";
    const nextRoute = this.props.user.email
      ? "/remittance/recipient"
      : "/remittance/email";

    return initialValues.amount === "0.00000000" ||
      fetching ||
      !initialValues.rates ? (
      <Loading
        styles={{
          background: "transparent",
          minHeight: "unset",
          maxHeight: "calc(100% - 200px)",
          display: "flex",
          justifyContent: "center",
        }}
      />
    ) : (
      <>
        {this.props.hidetitle || (
          <div style={{ margin: "0 auto", width: "fit-content" }}>
            <Text
              size={12}
              thickness={6}
              style={{
                marginBottom: "16.5px",
                lineHeight: "2.33",
                textAlign: "center",
              }}
              as="div"
            >
              {type === "Sell"
                ? "How much are you selling?"
                : "How much are you sending?"}
            </Text>
          </div>
        )}
        <ContainerColumn
          onClick={this.handlePropagation}
          style={{
            width: this.props.hidetitle ? "auto" : "100%",
            margin: this.props.hidetitle ? "unset" : "0 auto",
            marginBottom: "50px",
          }}
        >
          <Formik
            validationSchema={validation}
            initialValues={initialValues}
            render={(props) => {
              const incomingCurrencies =
                this.state.currencies.incomingCurrencies?.filter(
                  (coin) => !coin.includes("TRON_")
                );

              const senderCurrency = this.context.state.senderCurrency;
              const recipientCurrency = this.context.state.recipientCurrency;

              let compareCurrency = this.state.countries.find(
                (country) => country.country === props.values.reciever_country
              );

              const currencyPair = `${senderCurrency}${recipientCurrency}`;

              let rate = Number(this.context.state.rates[currencyPair]);

              let USDrate = Number(
                this.context.state.rates[`${senderCurrency}BUSD`]
              );

              const exchangeRate = rate / USDrate;

              let countryNames = this.state.countries.map(
                (country) => country.country
              );

              const maximums = {
                NGN: (this.state.level === 2 ? 20000 : 2000) * exchangeRate,
              };

              const minimumAllowedFiatAmount =
                this.state.initialValues.transactionMinimums[currencyPair];

              const updateStateOnCurrencyChange = (currencyPair, e) => {
                const currencyPairRate = this.context.state.rates[currencyPair];

                if (props.values.fiat_amount) {
                  const fiatAmount = Number(props.values.fiat_amount);

                  const minimumAllowedFiatAmount =
                    this.state.initialValues.transactionMinimums[currencyPair];

                  const cryptoAmount = Number(
                    fiatAmount / currencyPairRate
                  ).toFixed(8);

                  const minimumAllowedCryptoAmount = (
                    minimumAllowedFiatAmount / currencyPairRate
                  ).toFixed(8);

                  const isBelowMinimumAllowedFiatAmount =
                    fiatAmount < minimumAllowedFiatAmount;

                  const newCryptoAmount = isBelowMinimumAllowedFiatAmount
                    ? minimumAllowedCryptoAmount
                    : cryptoAmount;

                  const newFiatAmount = isBelowMinimumAllowedFiatAmount
                    ? minimumAllowedFiatAmount
                    : fiatAmount;

                  props.handleChange({
                    ...e,
                    target: {
                      ...e.target,
                      name: "amount",
                      value: newCryptoAmount,
                    },
                  });

                  props.handleChange({
                    ...e,
                    target: {
                      ...e.target,
                      name: "fiat_amount",
                      value: newFiatAmount,
                    },
                  });
                }
              };

              const handleCryptoSelectChange = (e) => {
                e.persist();
                const { value } = e.target;

                this.context.updateState({ senderCurrency: value }, () => {
                  const currencyPair = `${value}${
                    compareCurrency && compareCurrency.currency
                  }`;
                  updateStateOnCurrencyChange(currencyPair, e);
                });
              };

              const handleFiatSelectChange = (e) => {
                e.persist();
                const { value } = e.target;

                this.context.updateState({ recipientCurrency: value }, () => {
                  const currencyPair = `${senderCurrency}${e.target.value}`;
                  updateStateOnCurrencyChange(currencyPair, e);
                });
              };

              const onHandleCrypto = (e) => {
                if (isNumeric(e.target.value) || e.target.value === "") {
                  props.handleChange({
                    ...e,
                    target: {
                      ...e.target,
                      name: "fiat_amount",
                      value: `${
                        Number(e.target.value) === 0
                          ? "0.00"
                          : Number(
                              parseFloat(e.target.value.replace(/,/g, "")) *
                                Number(this.context.state.rates[currencyPair])
                            ).toFixed(2)
                      }`,
                    },
                  });
                  props.handleChange({
                    ...e,
                    target: {
                      ...e.target,
                      name: e.target.name,
                      value: e.target.value.replace(/,/g, ""),
                    },
                  });
                }
              };

              const onHandleFiat = (e) => {
                if (isNumeric(e.target.value) || e.target.value === "") {
                  props.handleChange({
                    ...e,
                    target: {
                      ...e.target,
                      name: "amount",
                      value: `${Number(
                        Number(e.target.value) < 1
                          ? "0.00000000"
                          : parseFloat(e.target.value.replace(/,/g, "")) /
                              Number(this.context.state.rates[currencyPair])
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 8,
                        maximumFractionDigits: 8,
                      })}`,
                    },
                  });
                  props.handleChange({
                    ...e,
                    target: {
                      ...e.target,
                      name: e.target.name,
                      value: e.target.value.replace(/,/g, ""),
                    },
                  });
                }
              };

              const submit = () => {
                if (
                  Number(props.values.fiat_amount) >=
                    minimumAllowedFiatAmount &&
                  Number(props.values.fiat_amount) <=
                    maximums[recipientCurrency]
                ) {
                  this.context.updateState(
                    {
                      ...props.values,
                      senderCurrency,
                      recipientCurrency,
                      rate,
                      sender_email:
                        this.props.user.email ||
                        this.context.state.sender_email,
                      transaction_type: type,
                    },
                    () => this.props.history.push(nextRoute)
                  );
                }
              };

              const fiat_input_error =
                Number(props.values.fiat_amount) < minimumAllowedFiatAmount
                  ? `The minimum you can ${type.toLowerCase()} is ${recipientCurrency} ${Number(
                      minimumAllowedFiatAmount
                    ).toLocaleString()} `
                  : Number(props.values.fiat_amount) >
                    maximums[recipientCurrency]
                  ? `The maximum you can ${type.toLowerCase()} is ${recipientCurrency} ${Number(
                      maximums[recipientCurrency]
                    ).toLocaleString()}`
                  : "";

              return (
                <Container
                  onSubmit={props.handleSubmit}
                  autoComplete="off"
                  style={{ width: "435px" }}
                >
                  <div
                    style={{
                      textAlign: "left",
                      marginBottom: "12.5px",
                      width: "100%",
                    }}
                  >
                    <Text
                      size={14}
                      thickness={5}
                      style={{
                        lineHeight: "29px",
                        display: "block",
                        textAlign: "center",
                      }}
                    >
                      {type !== "Sell" ? (
                        <>
                          Send money from{" "}
                          <Input
                            sm={true}
                            borderless
                            options={COUNTRIES}
                            select={true}
                            title=""
                            name="sender_country"
                            value={props.values.sender_country}
                            error={props.errors.sender_country}
                            onChange={props.handleChange}
                          />{" "}
                          to
                        </>
                      ) : (
                        <div style={{ margin: "12px 0" }}>
                          How much do you want to sell?
                        </div>
                      )}
                    </Text>
                  </div>
                  <Notification error={formattedError} />
                  {type === "Sell" || (
                    <Input
                      large
                      options={[...countryNames, "South Africa (Coming Soon)"]}
                      select={true}
                      title=""
                      name="reciever_country"
                      value={props.values.reciever_country}
                      error={props.errors.reciever_country}
                      onChange={(e) => {
                        // handleCurrencyChange(e);
                        // props.handleChange(e);
                      }}
                    ></Input>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection:
                        type === "Sell" ? "column-reverse" : "column",
                      width: "100%",
                    }}
                  >
                    <InputSelect
                      type="text"
                      title={
                        type !== "Sell" ? "Recipient receives" : "You receive"
                      }
                      name="fiat_amount"
                      placeholder=""
                      selectValue={recipientCurrency}
                      selectChange={handleFiatSelectChange}
                      selectName="selectedReceiverCurrency"
                      options={["NGN"]}
                      value={Number(props.values.fiat_amount).toLocaleString()}
                      error={fiat_input_error}
                      onChange={onHandleFiat}
                    >
                      {" "}
                      <ExchangeRate
                        base={senderCurrency}
                        compare={recipientCurrency}
                        rate={Number(rate).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      />{" "}
                    </InputSelect>

                    <InputSelect
                      type="text"
                      title={`You ${type}`}
                      name="amount"
                      placeholder=""
                      selectName="selectedSendCurrency"
                      selectChange={handleCryptoSelectChange}
                      selectValue={senderCurrency}
                      options={incomingCurrencies}
                      value={props.values.amount}
                      error={props.errors.amount}
                      onChange={onHandleCrypto}
                    >
                      {" "}
                      <ExchangeRate
                        base=""
                        baseValue="USD equivalent"
                        compare="USD"
                        rate={Number(
                          props.values.fiat_amount / exchangeRate
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      />{" "}
                    </InputSelect>
                  </div>
                  <Button
                    as="button"
                    colored="true"
                    free="true"
                    wide="true"
                    type="submit"
                    onClick={submit}
                    style={{ textDecoration: "none" }}
                  >
                    Continue
                  </Button>
                </Container>
              );
            }}
          />
        </ContainerColumn>
      </>
    );
  }
}

const mapDispatchToProps = {
  create: create,
  getLevel: getLevel,
};
const mapStateToProps = ({ user }) => {
  return {
    user: { email: "", ...user.resp.data },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));

App.contextType = Context;
