import styled, { css } from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  margin-bottom: 20px;
  position: relative;

  .show-icon {
    width: 16px;
    height: 16px;
    display: block;
    position: absolute;
    top: 38px;
    right: 6%;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
  }
  label {
    font-size: 0.9722222222em;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #1f2023;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
  }
  input,
  select,
  textarea { 
    height: 45px;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid rgba(112, 112, 112, 0.25);
    outline: none;
    padding: 0 12px;
    transition: all 0.5s ease-in-out;
    font-size: 1.1111111111em;
    appearance: none;
    max-width:100%;
    :focus {
      box-shadow: 0 0 0 2px rgba(0, 181, 233, 0.35);
      border: 1px solid rgba(0, 181, 233, 8);
    }
  }

  textarea {
    height: 92px;
    padding: 12px;
  }
  select {
    padding: 0 15px;
    background-color: white;
    padding-left: 12px;
    padding-right: 20px;
    -webkit-appearance: none;
    background-image: url(https://res.cloudinary.com/drqqv1c2v/image/upload/v1544982078/left-arrow.svg);
    background-size: auto 7px;
    background-position: calc(100% - 15px) center;
    background-repeat: no-repeat;
  }
  ${({ isPassword }) =>
    isPassword &&
    css`
      input {
        padding-right: 42px;
      }
    `}
  ${({ borderless }) =>
    borderless &&
    css`
      display: inline-flex;
      margin-bottom: 0;
      width: fit-content;
      max-width: 150px;
      label {
        display: none;
      }
      select {
        font-weight: 500;
        line-height: 29px;
        color: #11b2e4;
        font-size: 1.5rem;
        padding-left: 0;
        border: none;
        width: fit-content;
        background-position: calc(100% - 2px) center;
        background-image: url(https://res.cloudinary.com/dpmxguze6/image/upload/v1582729284/dashboard_assets/chevron-down.svg);
        :focus {
          box-shadow: none;
          border: none;
        }
      }
    `}
    ${({ large }) =>
      large &&
      css`
       
        input,select {
          height:70px
          font-size: 1.4583333333em;
          font-weight: 500;
          line-height: 25px;
          text-align: left;
          background-position: calc(100% - 20px) center;
          background-size: auto 8px;
        }
      `}
      ${({ sm }) =>
        sm &&
        css`
          select {
            width: 100px;
          }
        `}
  @media (max-width:768px) {
    .show-icon {
      top: 40px;
    }
  }
`;
