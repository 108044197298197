import styled, { keyframes, css } from "styled-components";
const ascend = keyframes`
  from {
    transform: translateY(20px);
    opacity:0;
  }

  to {
    transform: translateY(0);
    opacity:1;
  }
`;

export const Container = styled.div`
  background-color: #f5f8f9;
  position: relative;
  ${({ white }) =>
    white &&
    css`
      background-color: #ffffff;
    `}
`;

export const SectionOne = styled.section`
  border-bottom: 1px solid rgba(112, 112, 112, 0.1);

  background-image: url("https://res.cloudinary.com/dpmxguze6/image/upload/v1570275320/header_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 12px;
  ${({ plain }) =>
    plain &&
    css`
      background-image: none;
    `}
  ${({ noborder }) =>
    noborder &&
    css`
      border: none;
    `}
    ${({ nopad }) =>
      nopad &&
      css`
        padding-top: 0;
      `}
`;

export const SectionTwo = styled.section`
  border-bottom: 1px solid rgba(112, 112, 112, 0.1);
  padding-top: 99px;
  padding-bottom: 72px;
  overflow-x: hidden;
  .slick-dots {
    button {
      border-radius: 25px;
      background-color: #d8d8d8;
      padding: 0;
      height: 4px;
      width: 12px;
      transition: all 300ms ease-in-out;
    }
    button:before {
      content: "";
    }
    .slick-active button {
      width: 36px;
      background-color: #00b3e4;
    }
  }
  .slick-dots li {
    width: auto;
    margin: 0 2px;
  }
`;

export const SectionThree = styled.section`
  border-bottom: 1px solid rgba(112, 112, 112, 0.1);
  padding: 55px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 870px) {
    flex-direction: column;
  }
`;

export const SectionFour = styled.section`
  padding: 80px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid rgba(112, 112, 112, 0.1);
  p {
    max-width: 280px;
    width: 100%;
    font-size: 1.25rem;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #545969;
    margin-top: 0;
    font-family: "Inter";
    margin-bottom: 25px;
  }
`;

export const DetailsContainer = styled.div`
  display: flex;
  justify-content: center;
  ${({ special }) =>
    special &&
    css`
      justify-content: space-between;
      .custom-grid {
        flex: 1;
      }
    `}
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const BigTitle = styled.h1`
  /* width: 661px; */
  font-size: 3.75rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #1f2023;
  margin: auto;
  font-family: "Inter";
  animation: ${ascend} 2s linear 0s 1;
`;

export const BigSubTitle = styled.h2`
  max-width: 736px;
  font-size: 1.5625rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #545969;
  margin: 20px auto 0;
  font-family: "Inter";
  animation: ${ascend} 3s linear 0s 1;
`;

export const SubTitle = styled.h4`
  font-size: 1.5rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #1f2023;
  margin-top: 0;
  font-family: "Inter";
  margin-bottom: 20px;
`;

export const Support = styled(SubTitle)`
  margin-right: 84px;
  margin-bottom: 0;
  @media (max-width: 870px) {
    margin-right: 0;
    margin-bottom: 20.216px;
  }
`;

export const SampleProfile = styled.div`
  margin: auto;
  margin-top: 70px;
  position: relative;
  max-width: 569px;
  width: 100%;
  cursor: pointer;
  img {
    height: auto;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    margin: 0 auto;
    background-color: transparent;
    padding: 0;
    display: block;
  }
  &:before {
    display: block;
    content: "";
    background-image: url(https://res.cloudinary.com/dpmxguze6/image/upload/v1568056997/user-interface-agent-usability-play-video-multimedia-media-player.svg);
    height: 48px;
    width: 48px;
    position: absolute;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
    cursor: pointer;
    transition: all 300ms ease-in-out;
  }
  &:hover {
    &:before {
      display: block;
      content: "";
      transform: scale(1.2);
    }
  }
`;

export const NewsLetterTitle = styled.h3`
  max-width: 296px;
  font-size: 1.25rem;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.35;
  letter-spacing: normal;
  margin: 84px auto 0;
  text-align: center;
  color: #1f2023;
  font-family: "Inter";
`;

export const EmailCollectorContainer = styled.form`
  max-width: 370.4px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  box-shadow: 0 3px 4px 0 rgba(210, 224, 229, 0.8);
  background-color: #ffffff;
  margin: 28px auto 100px;
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  transition: all 0.2s ease-in-out;
  input {
    width: calc(100% - 45px);
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.35;
    letter-spacing: normal;
    height: 100%;
    outline: none;
    border: none;
    font-family: "Inter";
    padding-left: 12px;
  }
  button {
    width: 45px;
    height: 100%;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 179, 228, 0.16);
    background-color: #00b3e4;
    border: none;
    outline: none;
    color: #ffffff;
    font-family: "Inter";
  }
  &:focus-within {
    box-shadow: 0 0 0 4px rgba(0, 181, 233, 0.35);
  }
`;

export const FloatingButton = styled.div`
  position: fixed;
  bottom: 2em;
  left: 2em;
  img {
    width: 210px;
    height: auto;
  }
  @media (max-width: 768px) {
    img {
      width: 182px;
      height: auto;
    }
  }
`;
