import React from "react";
import { ThemeProvider } from "styled-components";
import Layout from "./Layout";
import theme from "../theme";
import Routes from "./Routes";
import RemittanceContext from "./Context";
import Request from "../../request";

const API_URL = process.env.REACT_APP_PAYMENT_GATEWAY_API_BASE_URL.replace(
  "/api/v1",
  ""
);

class App extends React.Component {
  constructor(props) {
    super(props);

    this.updateState = (update, cb) => {
      this.setState(
        ({ state }) => ({
          state: { ...state, ...update },
        }),
        () => cb && cb()
      );
    };

    this.state = {
      state: {
        amount: "0.00000000",
        fiat_amount: "500.0000000",
        rate: 0,
        reciever_country: "Nigeria",
        recipientCurrency: "NGN",
        recipient_username: "",
        senderCurrency: "",
        sender_country: "United States",
        sender_email: "",
        type: "",
        accountNumber: "",
        bankCode: "",
        accountName: "",
        bank: "",
        fetchedRate: false,
        rates: {
          BTCNGN: 4694835.42,
          DASHBUSD: 74.7054,
          DASHNGN: 34513.894799999995,
          TBTCBUSD: 10167.8148,
          TBTCNGN: 4694835.42,
          TDASHBUSD: 74.7054,
          TDASHNGN: 34513.894799999995,
        },
        rateKeys: {
          BTCNGN: "4694835.42",
          DASHBUSD: "74.7054",
          DASHNGN: "34513.894799999995",
          TBTCBUSD: "10167.8148",
          TBTCNGN: "4694835.42",
          TDASHBUSD: "74.7054",
          TDASHNGN: "34513.894799999995",
        },
        transactionMinimums: {
          NGN: 500,
          USD: 5,
          ZAR: 2000,
          BTCNGN: 500,
          DASHNGN: 500,
          CUSDNGN: 500,
          BUSDNGN: 500,
          BNBNGN: 500,
          ETHNGN: 5000,
          USDTNGN: 5000,
          TRON_USDTNGN: 500,
          BTCUSD: 1,
          DASHUSD: 1,
          CUSDUSD: 1,
          BUSDUSD: 1,
          BNBUSD: 1,
          ETHUSD: 1,
          USDTUSD: 1,
          TRON_USDTUSD: 10,
        },
        transaction_type: "",
      },
      updateState: this.updateState,
    };

    this.ratesFetchInterval = 0;
  }

  componentDidMount() {
    this.poolRate();

    this.setTransactionMinimums();

    document.addEventListener(
      "visibilitychange",
      this.handlePageVisibility,
      false
    );
  }

  componentWillUnmount() {
    document.removeEventListener(
      "visibilitychange",
      this.handlePageVisibility,
      false
    );
  }

  handlePageVisibility = () => {
    if (document.visibilityState === "hidden") {
      clearInterval(this.ratesFetchInterval);
    } else {
      this.poolRate();
    }
  };

  poolRate = () => {
    this.ratesFetchInterval = setInterval(() => this.fetchRate(), 3000);
  };

  fetchRate = async () => {
    try {
      const url = `${API_URL}/v3/currency/rate`;
      const response = await Request({ url });
      const { rates } = response.data.data;

      const ratesData = Object.entries(rates).reduce(
        (acc, [token, { rate, key }]) => {
          acc.rates[token] = rate;
          acc.rateKeys[token] = key;
          return acc;
        },
        { rates: {}, rateKeys: {} }
      );

      this.updateState({
        ...ratesData,
        fetchedRate: true,
      });
    } catch (err) {
      console.log({ err });
    }
  };

  setTransactionMinimums = async () => {
    try {
      const url = `${API_URL}/v2/currency/minimum-allowed`;
      const response = await Request({ url });
      const transactionMinimums = response.data.data[0];

      this.updateState({
        transactionMinimums,
      });
    } catch (error) {
      console.log({ error });
    }
  };

  render() {
    return (
      <RemittanceContext.Provider value={this.state}>
        <ThemeProvider theme={theme}>
          <Layout>
            <Routes />
          </Layout>
        </ThemeProvider>
      </RemittanceContext.Provider>
    );
  }
}

export default App;
