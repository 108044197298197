import React from "react";
import NotFound from "../components/errorBoundary";
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: [ 'production', 'staging' ],
  releaseStage: process.env.REACT_APP_ENVIRONMENT 
})

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    Bugsnag.getPlugin('react').createErrorBoundary(React)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <NotFound />;
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
