import { combineReducers } from "redux";
import user from "../components/authentication/User.reducer";
import profile from "../components/page/profile.reducer";
import confirm from "../components/authentication/Confirm.reducer";
import change from "../components/authentication/ChangePassword.reducer";
import reset from "../components/authentication/ResetPassword.reducer";
import update from "../components/dashboard/profile/update.reducer";
import register from "../components/authentication/Register.reducer";
import addresses from "../components/dashboard/addresses/Addresses.reducer";
import twofactor from "../components/dashboard/settings/Twofactor.reducer";

export default combineReducers({
  twofactor,
  user,
  register,
  addresses,
  change,
  reset,
  update,
  confirm,
  profile
});
