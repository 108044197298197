import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";

const Faq = lazy(() => import("../../containers/FAQ"));
const Terms = lazy(() => import("../../containers/Terms"));
const Auth = lazy(() => import("../../containers/Authentication"));
const Merchant = lazy(() => import("../../containers/Merchant"));
const OnBoarding = lazy(() => import("../../components/onboarding"));

const App = () => {
  return (
    <Suspense
      fallback={
        <div></div>
      }
    >
      <Switch>
        <Route exact path="/faq" component={Faq} />
        <Route exact path="/terms" component={Terms} />
        <Route path="/dashboard" component={Merchant} />
        <Route path="/home" component={OnBoarding} />
        <Route path="/" component={Auth} />
      </Switch>
    </Suspense>
  );
};

export default App;
