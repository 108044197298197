import React, { useState, useEffect, useCallback } from "react";
import Steps, { Step } from "rc-steps";
import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";
import { withRouter } from "react-router-dom";

const App = (props) => {
  const [step, setStep] = useState(0);
  const { pathname } = props.location;

  const handleSetStep = useCallback(() => {
    const allStep = ["amount", "email", "recipient", "review"];
    const activeRoutePath = pathname.split("/");
    const routeName = activeRoutePath[2].split("?")[0];

    const stepIndex = allStep.indexOf(routeName);
    setStep(stepIndex);
  }, [pathname]);

  useEffect(() => {
    handleSetStep();
  });

  return (
    <Steps progressDot size="big" current={step}>
      <Step
        title="Amount"
        onClick={() => props.history.push("/remittance/amount")}
      />
      <Step
        title="Your Email"
        onClick={() => props.history.push("/remittance/email")}
      />
      <Step
        title="Recipient"
        onClick={() => props.history.push("/remittance/recipient")}
      />
      <Step
        title="Review"
        onClick={() => props.history.push("/remittance/review")}
      />
    </Steps>
  );
};
export default withRouter(App);
