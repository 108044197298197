import React from "react";
import { Text } from "./styles/elements";

const App = ({ base = "BTC", compare = "USD", rate = "9,995", baseValue }) => (
  <Text size={7} thickness={6} style={{ marginTop: "7.5px", display: "block" }}>
    <Text
      size={7}
      thickness={6}
      style={{ marginTop: "7.5px" }}
      color={baseValue ? "black" : "blue"}
      variation={4}
    >
      {baseValue || 1}{" "}
    </Text>{" "}
    {base} {baseValue ? " - " : " = "}
    <Text
      size={7}
      thickness={6}
      style={{ marginTop: "7.5px" }}
      color="blue"
      variation={4}
    >
      {rate}
    </Text>{" "}
    {compare}
  </Text>
);

export default App;
