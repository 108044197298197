import React from "react";
import { Container } from "./elements";
import ContentLoader from "react-content-loader";
// import ReactPlaceholder from "react-placeholder";
// import "react-placeholder/lib/reactPlaceholder.css";

// export const Spinner = () => (
//   <Loader>
//     <div className="xyz-out" />
//     <div className="xyz-in" />
//   </Loader>
// );

// const App = ({ styles, placeholder }) => (
//   <Container style={{ ...styles }}>
//     <Spinner />
//     {/* {placeholder ? (
//       <ReactPlaceholder
//         type="text"
//         ready={false}
//         rows={6}
//         color="#E0E0E0"
//       ></ReactPlaceholder>
//     ) : (
//       <Spinner />
//     )} */}
//   </Container>
// );

const MyLoader = ({ styles, placeholder, ...props }) => (
  <Container style={{ ...styles }}>
    <ContentLoader
      speed={2}
      width={410}
      height={400}
      viewBox="0 0 435 420"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      style={{
        maxWidth: "100%",
        width: "410px",
        height: "400px",
      }}
      {...props}
    >
      <rect x="7" y="39" rx="0" ry="0" width="95%" height="20" />
      <rect x="5" y="100" rx="5" ry="5" width="95%" height="61" />
      <rect x="8" y="208" rx="5" ry="5" width="95%" height="61" />
      <rect x="17" y="339" rx="10" ry="10" width="92%" height="52" />
    </ContentLoader>
  </Container>
);

export default MyLoader;

// export default App;
