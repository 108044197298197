import styled, { keyframes, css } from "styled-components";

const fadeIn = keyframes`
  from {
    transform: translateY(5px);
    opacity:0;
  }

  to {
   transform: translateY(0px);
   opacity:1;
  }
`;
export const Container = styled.form`
  width: 100%;
  background: #ffffff;
  max-width: 450px;
  border-radius: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
  padding: 27px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  animation: ${fadeIn} 0.5s linear;
  transition: all 200ms linear;
  h1 {
    margin: 0;
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #00b3e4;
    margin-bottom: 24px;
  }
  p {
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #545969;
    margin-bottom: 30px;
    margin-top: 0;
  }
  @media (max-width: 995px) {
    padding: 22px 14px;
  }
  @media (max-width: 768px) {
    width: 100% !important;
  }
`;

export const More = styled.div`
  padding: 11px 15px;
  border-radius: 2.5px;
  border: solid 0.5px rgba(138, 108, 59, 0.15);
  background-color: #fcf8e3;
  margin-top: 26px;
  .head {
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    color: #8a6c3b;
    display: block;
    margin-bottom: 5px;
  }
  .content {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #8a6c3b;
    a {
      color: #8a6c3b;
      text-decoration: underline;
    }
  }
`;

export const RecipientWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 100%;
  max-width: 500px;
  flex-wrap: wrap;

  .recipient {
    outline: none;
    border: none;
    flex: 1;
    width: 180px;
    border-radius: 5px;
    box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 0 10px;
    padding: 15px 30px;
    transition: 300ms ease-in-out;
    .value {
      position: absolute;
      left: -20%;
      background-color: #e16259;
      border-radius: 8.5px;
      height: 19px;
      color: #ffffff;
      padding-top: 3px;
      display: block;
      width: 55%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px 5px 6px 4px;
    }

    img {
      width: 54.9px;
      height: 54.9px;
      object-fit: contain;
    }

    :hover {
      transform: translateY(5px);
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    .coin-user {
      width: 60%;
      margin: 0 0 30px;
    }
  }
  @media (max-width: 414px) {
    .coin-user {
      width: 100%;
    }
  }
`;
export const ContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: 995px) {
    align-items: center !important;
    .spread {
      width: 100%;
      max-width: 100%;
    }
    ${({ home }) =>
    home &&
    css`
      min-height: auto !important;
    `}
  }
  
`;

export const Users = styled.div`
  width: 100%;
  height: 170px;
  position: absolute;
  top: 57px;
  bottom: 300px;
  overflow-y: auto;
`;
export const Account = styled.div`
  border-bottom: solid 0.5px rgba(84, 89, 105, 0.08);
  padding: 10px 0;
  width: 100%;
  margin-bottom: 20px;
  cursor: pointer;
`
