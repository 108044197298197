import React from "react";
import PropTypes from "prop-types";
import Error from "../notification";
import { InputContainer, RightDivider } from "./elements";

const App = ({
  name,
  error,
  title,
  large,
  value,
  options,
  disabled,
  onChange,
  selectDisabled,
  selectChange,
  selectValue,
  selectName,
  labellink,
  placeholder,
  borderless,
  children,
  style,
}) => {
  return (
    <div style={{ marginBottom: "20px", width: "100%" }}>
      <InputContainer borderless={borderless} large={large} style={style}>
        <div style={{ flex: 3 }}>
          <label>
            {title}
            {labellink && labellink}
          </label>

          <input
            disabled={disabled}
            value={value}
            type="text"
            placeholder={placeholder}
            onChange={onChange}
            name={name}
          />
        </div>
        <RightDivider>
          <select
            onChange={selectChange}
            name={selectName || name}
            value={selectValue}
            disabled={selectDisabled}
          >
            {options.map((val) => (
              <option key={val}>{val}</option>
            ))}
          </select>
        </RightDivider>
      </InputContainer>
      {children}

      <Error error={error} />
    </div>
  );
};

App.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  error: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string.isRequired,
  textArea: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  select: PropTypes.bool,
  disabled: PropTypes.bool,
};

App.defaultProps = {
  error: "",
  options: [],
  select: false,
  disabled: false,
};
export default App;
