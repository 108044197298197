const text = [
  {
    text:
      "Enter the amount in the local currency of the person you’re sending money to. The Crypto amount is calculated on the fly.",
    title: "Enter amount you want to send",
    icon: "",
  },
  {
    text:
      "Enter the bank account details of the person you’re sending money to. You can also send money to another Coinprofile user (Coming soon)",
    title: "Enter recipient details",
    icon: "",
  },
  {
    text:
      "Send Crypto from any wallet to the unique Crypto address  generated for your transaction. Money will automatically be sent to recepient’s account",
    title: "Send Crypto",
    icon: "",
  },
];

export default text;
