import styled, { keyframes, css } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity:0.9;
    transform:scale(0.98)
  }

  to {
    transform:scale(1)
   opacity:1;
  }
`;
export const Container = styled.div`
  z-index: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0, 28, 53, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  padding-top: 230px;

  span {
    max-width: 800px;
    width: 100%;
    height: auto;
  }
  .edit-address-cont {
    width: 100%;
    height: auto;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 5px 0 rgba(0, 179, 228, 0.16);
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: ${fadeIn} 0.8s linear;
    transition: all 100ms linear;
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
    background-color: rgba(0, 0, 0);
    iframe {
      border: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
  /* 4x3 Aspect Ratio */
  .edit-address-cont-4x3 {
    padding-top: 75%;
  }
  ${({ light }) =>
    light &&
    css`
      @media (max-width: 768px) {
      }
    `}
`;
