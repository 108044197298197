import React, { Suspense, lazy } from "react";

import Loading from "../../containers/Loading";

import { Switch, Route } from "react-router-dom";
import LandingPage from "./LandingPage";

const Amount = lazy(() => import("./forms/Amount"));
const Email = lazy(() => import("./forms/Email"));
const Recipient = lazy(() => import("./forms/RecipientRoutes"));
const Review = lazy(() => import("./forms/Review"));

const App = () => {
  return (
    <>
      <Switch>
        <Route exact path={`/remittance`} component={LandingPage} />
        <Suspense
          fallback={
            <Loading
              styles={{
                background: "transparent",
                minHeight: "unset",
                maxHeight: "calc(100% - 200px)",
                height: "400px",
              }}
            />
          }
        >
          <Route exact path={`/remittance/amount`} component={Amount} />
          <Route exact path={`/remittance/email`} component={Email} />
          <Route path={`/remittance/recipient`} component={Recipient} />
          <Route exact path={`/remittance/review`} component={Review} />
        </Suspense>
      </Switch>
    </>
  );
};

export default App;
