import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.nav`
  display: flex;
  max-width: 947px;
  min-height: 58px;
  padding: 0 10px;
  width: 100%;
  margin: 0 auto;
  display: flex;

  justify-content: space-between;
  align-items: center;
  margin-bottom: 82px;
  position: relative;

  .menu-cont-large {
    display: block;
  }
  .menu-cont-small {
    display: none;
    /* z-index: 1; */
  }
  .nav-logo {
    width: 130px;
    height: auto;
    display: block;
    /* z-index: 1; */
  }
  .nav-logo svg {
    width: 115px;
    height: auto;
  }
  .show-center {
    display: none;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 20px;
    position: fixed;
    z-index: 1;
    background: rgb(245,248,249);
    margin-top: 0;
    padding: 20px 10px;
    .menu-cont-large {
      display: none;
    }
    .hidden {
      display: none;
    }
    .menu-cont-small {
      display: block;
    }
    // .show-center {
    //   display: block;
    //   width: 100%;
    //   margin-top: 50px;
    //   overflow-y: scroll;
    //   .rc-steps {
    //     width: 100% !important;
    //   }
    // }
  }
`;
export const Main = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
`;
export const Button = styled.button`
    padding: 8px 16px;
  color: #1f2023;
  border-radius: 10px;
  background-color: transparent;
  border: none;
  margin-left: 32px;
  transition: all 250ms ease-in-out;
  font-size: 1.125rem;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: left;
  ${({ colored }) =>
    colored &&
    css`
      box-shadow: 0 2px 4px 0 rgba(0, 179, 228, 0.16);
      background-color: #00b3e4;
      color: #ffffff;
      &:hover {
        background-color: #00a1cd;
        transform: translateY(-0.7px);
        /* box-shadow: 0 7px 14px rgb(215, 228, 231), 0 3px 6px rgb(215, 228, 231); */
      }
    `};
  ${({ free }) =>
    free &&
    css`
      margin: 0;
    `}
  ${({ wide }) =>
    wide &&
    css`
      width: 100%;
      text-align: center;
      margin-top: 10px;
      padding: 13px 11px;
    `}
    ${({ out }) =>
      out &&
      css`
        background-color: #eb6262;
        &:hover {
          background-color: #cc1a1a;
        }
      `}

`;

export const LinkButton = styled(Link)`
  padding: 10px 11px;
  color: #757a88;
  border-radius: 10px;
  background-color: transparent;
  border: none;
  margin-left: 32px;
  transition: all 0.25s ease-in-out;
  font-size: 1.125rem;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: left;
  text-decoration:none;
  font-family: "Inter";
  &:hover{
  color: #1f2023;
  }
  ${({ outlined }) =>
  outlined &&
  css`
    box-shadow: 0 2px 4px 0 rgba(0, 179, 228, 0.16);
    background-color:  #00b3e41a;
    color: #00b3e4;
    padding: 8px 16px;
    &:hover {
      color: #ffffff;
      transform: translateY(-0.7px);
      background-color: #00a1cd;
      box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
        0 3px 6px rgba(0, 0, 0, 0.08);
    }
  `};
  ${({ colored }) =>
    colored &&
    css`
      box-shadow: 0 2px 4px 0 rgba(0, 179, 228, 0.16);
      background-color: #00b3e4;
      color: #ffffff;
      padding: 8px 16px;
      &:hover {
        color: #ffffff;
        transform: translateY(-0.7px);
        background-color: #00a1cd;
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
          0 3px 6px rgba(0, 0, 0, 0.08);
      }
    `};
  ${({ free }) =>
    free &&
    css`
      margin: 0;
    `}
  ${({ wide }) =>
    wide &&
    css`
      width: 100%;
      text-align: center;
      margin-top: 10px;
      padding: 13px 11px;
    `}
    ${({ out }) =>
      out &&
      css`
        background-color: #eb6262;
        padding: 8px 16px;
        &:hover {
          color: #ffffff;
          background-color: #cc1a1a;
        }
      `}
`;

export const BurgerButton = styled.div`
  background-color: transparent;
  border-radius: 2px;
  width: 28px;
  height: 24px;
  padding: 0 6px;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
  font-family: "Inter";
  .bar {
    width: 100%;
    border: 1px solid #00b5e9;
    transition: transform ${({ expand }) => (expand ? "0.57s " : "0.8s")}
      cubic-bezier(0.4, 0.01, 0.165, 0.99);
    border-radius: 3px;
    position: absolute;
    width: 80%;
  }
  .bar:nth-child(2) {
    transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    opacity: ${({ expand }) => (expand ? "0 " : "1")};
    transition-delay: ${({ expand }) => (expand ? "0s" : ".2s")};
  }
  .bar:nth-child(1) {
    top: 1px;
    transform: ${({ expand }) =>
      expand
        ? "rotate(45deg) translate(39%,7px)"
        : "rotate(0deg) translate(0,0)"};
  }
  .bar:nth-child(3) {
    transform: ${({ expand }) =>
      expand
        ? "rotate(-45deg)translate(30%,-6px)"
        : "rotate(0deg) translate(0,0)"};
    bottom: 1px;
  }
`;

export const Menu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(245, 248, 249);
  padding-top: ${({ expand }) => (expand ? "15px" : "0")};
  padding-bottom: ${({ expand }) => (expand ? "25px" : "0")};
  transition: all 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition-delay: ${({ expand }) => (expand ? "0.42s" : "0.32s")};
  max-height: ${({ expand }) => (expand ? "250px" : "0px")};
  overflow: hidden;
  z-index: 1;
  a {
    margin-top: 5px;
    margin-left: 0;
    transform: ${({ expand }) =>
      expand ? "scale(1) translateY(0px)" : "scale(1.11) translateY(-65px)"};
    opacity: ${({ expand }) => (expand ? "1" : "0")};
    transition: transform ${({ expand }) => (expand ? "0.5s" : "0.27s")}
        cubic-bezier(0.4, 0.01, 0.165, 0.99),
      opacity ${({ expand }) => (expand ? "1.2s" : ".2s")}
        cubic-bezier(0.4, 0.01, 0.165, 0.99);
  }
  a:nth-child(4) {
    transition-delay: ${({ expand }) => (expand ? "0.78s" : "0.26s")};
  }

  a:nth-child(3) {
    transition-delay: ${({ expand }) => (expand ? "0.66s" : "0.34s")};
  }

  a:nth-child(2) {
    transition-delay: ${({ expand }) => (expand ? "0.50s" : "0.42s")};
  }
  a:nth-child(1) {
    transition-delay: ${({ expand }) => (expand ? "0.42s" : "0.46s")};
  }
  @media (min-width: 767px){
    display: none;
  }
`;

export const ContainerLogo = styled.nav`
  display: flex;
  max-width: 300px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  ${({ lean }) =>
    lean &&
    css`
      width: auto;
      max-width: auto;
    `}
  @media (max-width: 767px) {
    max-width: 285px;
  }
`;
