import styled, { css } from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  height: 70px;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid rgba(112, 112, 112, 0.25);
  padding: 0 12px;
  transition: 300ms ease-in-out;
  :focus-within {
    box-shadow: 0 0 0 2px rgba(0, 181, 233, 0.35);
    border: 1px solid rgba(0, 181, 233, 8);
  }
  .show-icon {
    width: 16px;
    height: 16px;
    display: block;
    position: absolute;
    top: 38px;
    right: 6%;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
  }
  label {
    font-size: 0.9722222222em;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 15px;
    letter-spacing: normal;
    text-align: left;
    color: #545969;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
  }
  input,
  select,
  textarea {
    border: none;
    outline: none;
    transition: all 0.5s ease-in-out;
    appearance: none;
    max-width: 100%;
    font-size: 1.5em;
    font-weight: 500;
    line-height: 31px;
    text-align: left;
  }

  textarea {
    height: 92px;
    padding: 12px;
  }
  select {
    padding: 0 15px;
    background-color: white;
    padding-left: 12px;
    padding-right: 36px;
    -webkit-appearance: none;
    background-image: url(https://res.cloudinary.com/drqqv1c2v/image/upload/v1544982078/left-arrow.svg);
    background-size: auto 7px;
    background-position: calc(100% - 8px) center;
    background-repeat: no-repeat;
  }

  ${({ large }) =>
    large &&
    css`
      height: 70px input;
      select {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 25px;
        text-align: left;
        background-position: calc(100% - 20px) center;
        background-size: auto 8px;
      }
    `}
  @media (max-width:768px) {
    .show-icon {
      top: 40px;
    }
    input,
    select {
      padding-left: 0;
    }
    select {
      padding: 0;
      background-position: calc(100%) center;
      width: 100%;
      padding-right: 16px;
      min-width: 69px;
    }
  }
`;

export const RightDivider = styled.div`
  flex: 1;
  @media (max-width: 768px) {
    flex: 2;
  }
`;
