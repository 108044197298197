import { SIGN_OUT } from "./constants/actionTypes";

export const signoutUser = () => ({
  type: SIGN_OUT.default
});

export const signoutUserBoundActionCreator = () => async dispatch => {
  await dispatch(signoutUser());
};

export default signoutUserBoundActionCreator;
